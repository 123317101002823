<template>
  <LayoutTelas id="tela_notas" :titulo="this.$route.meta.titulo">
    <div>   
      <loading 
        :active.sync="dados.show_loader" 
        :can-cancel="false" 
        :is-full-page="true"
        color="#f68b29"/>
      <b-form
        class="filtro"
        @submit.prevent="listarDados(0)">
        <InputBasico
          ref="refDataInicial"
          label="Data inicial"
          name="dataInicial"
          v-model="dados.dataInicial"
          type="date"
          min=1
          max=12
          size="sm"
          class="inputDataInicial"
          v-validate="{ required: dados.docNum == ''}"
          :disabled="dados.disabledCampos"
          :errorsBag="errors"/>
        <InputBasico
          ref="refDataFinal"
          label="Data final"
          name="dataFinal"
          v-model="dados.dataFinal"
          type="date"
          min=1
          max=12
          size="sm"
          class="inputDataFinal"
          v-validate="{ required: dados.docNum == ''}"
          :disabled="dados.disabledCampos"
          :errorsBag="errors"/>
        <InputSelect
          ref="refTiposNotas"
          label="Tipo de documento"
          name="tipoNota"
          :options="getTiposNota()"
          class="inputTiposNotas"
          size="sm"
          v-model="dados.tipoNota"
          :disabled="dados.disabledCampos"
          :errorsBag="errors"/>
        <InputBasico
          ref="refDocNum"
          label="Documento ID"
          name="docNum"
          v-model="dados.docNum"
          info="ID do documento no ERP de Origem"
          type="text"
          size="sm"
          class="inputDocNum"
          :disabled="dados.disabledCampos"
          :errorsBag="errors"/>
          <div class="notaCard">
            <b-form-checkbox  v-model="dados.checkedOnlyErros" name="check-button" :disabled="dados.disabledCampos" @change="marcouCheckStatus"  switch>
              Somente com divergências
            </b-form-checkbox>
          </div>
        <b-button type="submit" size="sm" variant="primary" class="btnProcessar" :disabled="dados.disabledCampos">Processar</b-button>
        <b-button type="reset" size="sm" variant="success" class="btnLimpar" :disabled="dados.disabledCampos">Limpar</b-button>
      </b-form>
    </div>
    
    <div class="loader" v-if="dados.show_loader_nota">
      <b-spinner v-if="!dados.checked_nota" class="text-center justify-content-between" variant="warning" label="Loading..."></b-spinner>
      <v-icon v-if="dados.checked_nota" scale="2" name="check" class="loaderCheck"/>
      <label class="concluido">Enviando atualização da nota...</label>
    </div>
    
    <div v-if="dados.show_loader !== true">
      <h5>Resultado da busca</h5>

      <div class="resultado" v-if="dados.total > 0">
        {{dados.total > 1 ? 'Encontradas':'Encontrada'}} <strong>{{dados.total}}</strong> {{dados.total > 1 ? 'ocorrências':'ocorrência'}}.
      </div>

      <div v-if="dados.listaItens.length > 0">
        <b-table
          striped
          hover
          bordered
          small
          id="myTable"
          responsive="sm"
          head-variant="dark"
          :items="dados.listaItens"
          :fields="dados.fields"
          :per-page="paginacao.limitePorPagina"
          :current-page="paginacao.paginaAtual"
          :tbody-tr-class="rowClass">
          
          <template #head(actions)="data">
            <center>Ações</center>
          </template>

          <template #cell(doc_status)="row">
            <center>
              {{getStatusNF(row.item)}}
            </center>
          </template>

          <template #cell(entrada_saida)="row">
            <center>
              {{getTipoNF(row.item)}}
            </center>
          </template>

          <template #cell(actions)="row">
            <center>
              <b-button :disabled="row.item.erros && row.item.erros.length == 0" class="btn btn-danger btnAction" size="sm" @click="row.toggleDetails" title="Visualizar erros"><v-icon scale="1" :name="row.detailsShowing ? 'clipboard':'clipboard-list'"/></b-button>
              <b-button v-if="showButton(row.item)" class="btn btn-success btnAction" size="sm" @click="enviarStatus(row.item)" title="Enviar status da NF ao ERP de destino"><v-icon scale="1" name="eye"/></b-button>
            </center>
          </template>

          <template #row-details="row">
            <b-card class="detalhe">
              <span>Erros</span>

              <b-row class="sm-2" v-for="(erro,idx) in row.item.erros" :key="idx">
                <b-col sm="2" class="text-sm-right"><b>-</b></b-col>
                <b-col>{{erro.mensagem}}</b-col>
              </b-row>

            </b-card>
          </template>
        </b-table>

        <div id="footerTable">
          <div>
            <b-form-select v-model="paginacao.limitePorPagina" size="sm" :options="paginacao.limitOptions" @change="listarDados(1)"></b-form-select>
          </div>
          
          <div class="paginacao" v-if="dados.listaItens.length > 0">
            <b-pagination
              class="paginacao__seletor"
              :total-rows="paginacao.totalDePaginas"
              :per-page="paginacao.limitePorPagina"
              v-model="paginacao.paginaAtual"
              aria-controls="myTable"
              size="sm"/>
          </div>      
        </div>
        
      </div>
      <div v-else>
        Nenhum registro encontrado.
      </div>
    </div>    
  </LayoutTelas>
</template>

<script>
import {listarNotasTodas,processaAtualizarNotaSaida} from "../../common/services/notasService";

import * as dataHelper from '@/common/helpers/DataHelper'
import { getUserObj } from '@/common/localStorage/localStorage'

import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
// import teste from '../../json';

export default {
  data() {
    return {
      dados: {
        isLoading: false, 
        disabledCampos: false,
        dataInicial:'',
        dataFinal: '',
        docNum: '',
        tipoNota: null,
        data_loaded: false,
        show_loader: false,
        show_processing: false,
        show_loader_nota: false,
        checked_nota: false,
        checkedOnlyErros: false,
        listaItens: [],
        fields: [
          { key: "nota.docnum", label: "ID Nota", sortable: true },
          { key: "nota.doc_status", label: "Status", sortable: true },
          { key: "nota.data_lancamento", label: "Data Entrada/Saída", sortable: true },
          { key: "nota.entrada_saida", label: "Tipo NF", sortable: true },
          { key: "nota.nfnum", label: "Nº NF", sortable: true },
          { key: "nota.series", label: "Série", sortable: true },
          { key: "actions", label: "Ações", sortable: true }
        ]
      },
      paginacao: {
        paginaAtual: 1,
        limitePorPagina: 10,
        totalDePaginas: 0,
        limitOptions: [
          { value: 10, text: '10 por página' },
          { value: 25, text: '25 por página' },
          { value: 50, text: '50 por página' },
          { value: 75, text: '75 por página' },
          { value: 100, text: '100 por página' }
        ]
      }
    }
  },
  components: {
    InputBasico,
    InputSelect
  },
  created() {
    //remover esta linha
    // this.listarDados(1);
  },
  sockets: {
    ALTERAR_FORM_NOTA: async function (data) {
      this.dados.disabledCampos = true
      Object.keys(data).forEach(key => {
        this.dados[key] = data[key]
      });
    },
    HABILITAR_FORM_NOTA: async function (data) {
      this.dados.disabledCampos = false
    },
    RETORNO_PROCESSAMENTO_FORM_NOTA: async function (data) {
      this.dados.itemProcessamento = data
      this.$validator.reset()
      this.errors.clear()
    }
  },
  methods: {
    getTiposNota() {
      return [
        { text: 'Todas', value: null},
        { text: 'Entrada', value: "1"},
        { text: 'Saída', value: "2"},
        { text: 'Outros documentos - 3', value: "3"},
        { text: 'Outros documentos - 4', value: "4"},
      ]
    },
    async listarDados(pagina) {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.dados.listaItens = [];
          this.dados.show_loader = true;
          this.dados.disabledCampos = true;

          let filtro = {
            date_low: this.dados.dataInicial,
            date_high: this.dados.dataFinal
          }

          if (this.dados.tipoNota)
            filtro.entrada_saida = this.dados.tipoNota;

          if  (this.dados.docNum != "")
            filtro.listaDocnum = [{docnum:this.dados.docNum}];

          let ret = (await listarNotasTodas(filtro)).data;

          if (this.dados.checkedOnlyErros) {
            this.dados.listaItens = ret.filter(l => l.erros && l.erros.length > 0);
          } else {
            this.dados.listaItens = ret;
          }
          // this.dados.listaItens = teste;

          if (this.dados.listaItens.length > 0)
            this.paginacao.totalDePaginas = this.dados.listaItens.length;
            
          this.dados.show_loader = false;
          this.dados.disabledCampos = false;
        }
      });
    },
    rowClass(item, type) {      
      if (item && type === 'row') {
        if (!this.validaJson(item)) {
          return "table-danger"
        }
      }      
    },
    validaJson(ArrayIn) {
      let ret = true;

      if (ArrayIn.erros && ArrayIn.erros.length > 0) ret = false;

      return ret;
    },
    showButton(objeto){
      return objeto.nota.entrada_saida == "2" && (objeto.nota.cancel == "X" || ["5","6","8","4"].indexOf(objeto.nota.doc_status) != -1);
    },
    async enviarStatus(objeto) {
      this.dados.show_loader_nota = true;
      this.dados.disabledCampos = true;

      let filtro = {
        date_low: '',
        date_high: '',
        nota: objeto.nota
      }
      
      let ret = (await processaAtualizarNotaSaida(filtro)).data;

      this.dados.show_loader_nota = false;
      this.dados.disabledCampos = false;
    },
    getTipoNF(value) {
      switch (value.entrada_saida) {
        case "1":
          return "Entrada";
        case "2":
          return "Saída";
        case "3":
          return "Outros doc. 3";      
        case "4":
          return "Outros doc. 3";      
        default:
          return "Indefinido"
      }
    },
    getStatusNF(value) {
      if (value.cancel == "X") {
        return "Cancelada"
      } else {
        switch (value.doc_status) {
          case "6":
          case "8":
            return "Inutilizado";
          case "4":
            return "Rejeitado";
          case "5":
            return "Denegado";
          default:
            return "Faturado"
        }
      }
    },
    limparCampos() {
      this.dados = {
        dataInicial:'',
        dataFinal: '',
        docNum: '',
        tipoNota: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/common/scss/breakpoints.scss";

  #tela_notas {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @media (min-width: $medium-size) {
      min-width: $small-size;
    }
  }

  .filtro {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: $medium-size) {
      width: 100%;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 0.4em;
    }

  }

  .input {
    &DataInicial {
      @media (min-width: $medium-size) {
        grid-column: 1 / 3;
      }
    }

    &DataFinal {
      @media (min-width: $medium-size) {
        grid-column: 3 / 6;
      }
    }

    &TiposNotas {
      @media (min-width: $medium-size) {
        grid-column: 6 / 9;
      }
    }

    &DocNum {
      @media (min-width: $medium-size) {
        grid-column: 9 / 12;
      }
    }
  }

  .btn {
    &Processar {
      @media (min-width: $medium-size) {
        grid-column: 1 / 2;
        margin-bottom: 1.9rem;
      }
    }
    &Limpar {
      @media (min-width: $medium-size) {
        grid-column: 2 / 3;
        margin-bottom: 1.9rem;
      }
    }
  }

  .parametrizacao {
    margin: 0;
  }

  #footerTable {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .loader {
    @media (min-width: $medium-size) {
        grid-column: 1 / -1;
        align-self: center;
    }
    &Spinner {
        align-self: center;
    }
    &Check {
        color: #00cf2d;
    }
  }

  .paginacao {
    display: flex;
    justify-content: flex-end;

    &__seletor {
      margin: 0;
    }
  }

  button {
    margin-left: 4px;
  }

  .notaCard {
    @media (min-width: $medium-size) {
        grid-column: 1 / 10;
        padding-bottom: 1.5rem;
    }
  }
</style>